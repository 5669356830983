import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import { gql, useMutation } from "@apollo/client";
import { getUser, setUser } from "../../services/auth";
import DoneIcon from "@mui/icons-material/Done";
import { useMeasure } from "react-use";

const BrickNewsletter = ({ style, backgroundImageData, title, subtitle }) => {
  const { t } = useI18next();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [ref, { width }] = useMeasure();

  const getMarginX = () => {
    const margins = [
      { id: "xl", value: "240px" },
      { id: "lg", value: "160px" },
      { id: "md", value: "80px" },
      { id: "sm", value: "16px" },
      { id: "xs", value: "16px" },
    ];
    const regex = /@media \(max-width:([0-9]*\.[0-9]*)px\)/;
    for (let i = 0; i < margins.length; i++) {
      const breakpointString = theme.breakpoints.down(margins[i].id);
      const matches = regex.exec(breakpointString);
      const breakpoint =
        matches && matches.length > 0 ? Number(matches[1]) : null;
      if (breakpoint && width > breakpoint) return margins[i].value;
    }
    return "16px";
  };

  const UPDATE_PROGRESS = gql`
    mutation ($id: ID!, $subscribedNewsletter: Boolean!) {
      updateUser(
        input: {
          where: { id: $id }
          data: { subscribedNewsletter: $subscribedNewsletter }
        }
      ) {
        user {
          id
          userLastName
          userFirstName
          country
          profession
          areasOfExpertise
          email
          progress
          quizzResults
          subscribedEvents
          subscribedNewsletter
        }
      }
    }
  `;

  const [updateUser, { loading: loadingUpdateProgress }] =
    useMutation(UPDATE_PROGRESS);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState(
    getUser().subscribedNewsletter
  );
  const subscribe = () => {
    if (!getUser().token) {
      setShowLoginMessage(true);
      return;
    }
    updateUser({
      variables: {
        id: getUser().id,
        subscribedNewsletter: true,
      },
      headers: {
        Authorization: `Bearer ${getUser().token}`,
      },
    })
      .then((data) => {
        setUser({
          id: data.data.updateUser.user.id,
          userLastName: data.data.updateUser.user.userLastName,
          userFirstName: data.data.updateUser.user.userFirstName,
          country: data.data.updateUser.user.country,
          profession: data.data.updateUser.user.profession,
          areasOfExpertise: data.data.updateUser.user.areasOfExpertise,
          email: data.data.updateUser.user.email,
          token: getUser().token,
          progress: data.data.updateUser.user.progress,
          quizzResults: data.data.updateUser.user.quizzResults,
          subscribedEvents: data.data.updateUser.user.subscribedEvents,
          subscribedNewsletter: data.data.updateUser.user.subscribedNewsletter,
        });
        setHasSubscribed(true);
      })
      .catch((error) => {
        // Handle error.
        console.log(
          "newsletter subscription : an error occurred:",
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message
        );
      });
  };
  return (
    <Container ref={ref} maxWidth="false" style={style}>
      <div
        style={{
          position: "relative",
          objectFit: "cover",
          height: "613px",
        }}
      >
        <GatsbyImage
          style={{ height: "613px" }}
          alt={`Background Vimeo Live Event`}
          image={backgroundImageData}
        />
        <Grid
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 20,
            margin: "auto",
          }}
          display="flex"
          flexDirection="row"
          alignItems={isMobile ? "flex-end" : "center"}
          justifyContent={isMobile ? "center" : "flex-start"}
        >
          <Grid
            item
            lg={3}
            style={{
              backgroundColor: "white",
              padding: "22px",
              marginLeft: getMarginX(),
              marginRight: getMarginX(),
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                my: { xs: 2 },
              }}
              style={{ marginLeft: { lg: 20, md: 10 } }}
              maxWidth="500px"
            >
              <Typography color="grey.servier_1" variant="h2">
                {title}
              </Typography>
              <Typography
                color="grey.servier_1"
                variant="body1"
                style={{ fontWeight: "900" }}
              >
                {subtitle}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: isMobile ? "center" : "flex-end",
                }}
              >
                <Typography
                  variant="small1"
                  style={{ margin: "9px 0", textAlign: isMobile ? "" : "end" }}
                >
                  {t("newsletter.youCanUnsubscribe")}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ px: 5, py: 1, minWidth: { xs: "342px", xl: "0px" } }}
                  style={{
                    letterSpacing: "0.1em",
                    minWidth: "100px",
                    backgroundColor: hasSubscribed
                      ? theme.palette.servier.green
                      : "",
                    width: isMobile ? "100%" : "150px",
                  }}
                  onClick={subscribe}
                >
                  {hasSubscribed && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DoneIcon />
                    </div>
                  )}
                  <Typography variant="textButton" style={{ margin: "5px" }}>
                    {hasSubscribed
                      ? t("newsletter.subscribed")
                      : t("newsletter.subscribe")}
                  </Typography>
                </Button>

                {showLoginMessage && (
                  <Typography
                    variant="body1"
                    color="servier.red"
                    style={{ margin: 5 }}
                  >
                    {t("newsletter.messageLogin")}
                  </Typography>
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default BrickNewsletter;
