import React, { useMemo, useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import ListDropdown from "../../components/lists/dropdown";
import CardLink from "../../components/cards/card-link";
import { GatsbyImage } from "gatsby-plugin-image";
import CustomButtonText from "../../components/button/custom-button";
import ListOfCards from "../../components/cards/list-of-cards";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getAlternateLang } from "../../utils/alternate-lang";
import NoResult from "../../components/lists/noResult";
import theme from "../../gatsby-theme-material-ui-top-layout/theme";
import PrivateRoute from "../../components/private-route";
import BrickNewsletter from "../../components/bricks/newsletter";
import { gtmPushUserInfo } from "../../services/auth";

export const query = graphql`
  fragment StrapiNewsletterCoursesFragment on StrapiNewsletterCourses {
    Newsletter {
      title
      subtitle
      background {
        alternativeText
      }
    }
    backgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query Courses($language: String!) {
    coursesEn: allStrapiCourse(
      filter: {
        locale: { eq: "en" }
        # date: { ne: null }
      } # sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          id
          strapiId
          localizations {
            id
          }
          Title
          Slug
          CME
          credits
          hours
          minutes
          therapeutical_area {
            Name
            Slug
            color
          }
          Thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 223)
              }
            }
          }
        }
      }
    }
    coursesEs: allStrapiCourse(
      filter: {
        locale: { eq: "es-PA" }
        # date: { ne: null }
      } # sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          id
          strapiId
          localizations {
            id
          }
          Title
          Slug
          CME
          credits
          hours
          minutes
          therapeutical_area {
            Name
            Slug
            color
          }
          Thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 223)
              }
            }
          }
        }
      }
    }
    therapeuticalAreas: allStrapiTherapeuticalArea {
      edges {
        node {
          Name
          Slug
          color
        }
      }
    }
    strapiNewsletterCoursesEn: strapiNewsletterCourses(locale: { eq: "en" }) {
      ...StrapiNewsletterCoursesFragment
    }
    strapiNewsletterCoursesEs: strapiNewsletterCourses(
      locale: { eq: "es-PA" }
    ) {
      ...StrapiNewsletterCoursesFragment
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Courses = ({ data }) => {
  const { t, language } = useI18next();
  const courses =
    language === "en" ? data.coursesEn.edges : data.coursesEs.edges;
  const newsletter =
    language === "en"
      ? data.strapiNewsletterCoursesEn
      : data.strapiNewsletterCoursesEs;
  const [activeSlugs, setActiveSlugs] = useState([]);
  const [seeHowMany, setSeeHowMany] = useState(3);

  const isFiltered = useMemo(() => {
    return activeSlugs.length > 0;
  }, [activeSlugs]);

  const filteredCourses = useMemo(() => {
    return activeSlugs.length > 0
      ? courses.filter((ec) =>
          activeSlugs.includes(ec.node.therapeutical_area.Name)
        )
      : courses;
  }, [activeSlugs, courses]);

  const coursesCME = filteredCourses.filter((c) => c.node.CME);
  const generalCourses = filteredCourses.filter((c) => !c.node.CME);
  const seo = {
    metaTitle: t("metaData.courses.metaTitle"),
    metaDescription: t("metaData.courses.metaDescription"),
    linkAlternateHref: "/" + getAlternateLang(language) + "/courses",
    linkHrefLang: getAlternateLang(language),
  };

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Courses",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  const onChangeTherapeuticalAreas = (values) => {
    setSeeHowMany(values.length > 0 ? 6 : 3);
    setActiveSlugs(values);
  };

  const onLoadMore = () => {
    setSeeHowMany((previousValue) => previousValue + 3);
  };
  const getCardFromCourse = (course, t) => {
    return (
      <CardLink
        title={course.node.Title}
        type="course"
        image={
          <GatsbyImage
            image={
              course.node.Thumbnail.localFile.childImageSharp.gatsbyImageData
            }
            alt={course.node.Thumbnail.alternativeText}
          />
        }
        date={course.node.date}
        labelText={course.node.CME ? t("course.courseCME") : t("course.course")}
        labelColor={theme.palette.servier.blue2}
        labelTextColor={theme.palette.primary.main}
        link={`/courses/${course.node.Slug}`}
        hours={course.node.hours}
        minutes={course.node.minutes}
        therapeuticAreaLabel={course.node.therapeutical_area.Name}
        therapeuticAreaColor={course.node.therapeutical_area.color}
        CME={course.node.CME}
      />
    );
  };

  return (
    <Layout seo={seo}>
      <ListDropdown onChange={onChangeTherapeuticalAreas} />
      {coursesCME.length > 0 ? (
        <ListOfCards
          title={t("course.coursesCME")}
          cards={coursesCME
            .slice(0, seeHowMany)
            .map((course) => getCardFromCourse(course, t, isFiltered))}
          button={
            coursesCME.length > seeHowMany ? (
              <CustomButtonText
                text="Load More"
                onClick={onLoadMore}
                style={{
                  letterSpacing: "0.1em",
                }}
              />
            ) : (
              <></>
            )
          }
          backgroundColor="#FFFFFF"
          disableCarousel={true}
        />
      ) : (
        <NoResult />
      )}
      {generalCourses.length > 0 ? (
        <ListOfCards
          title={t("course.generalCourses")}
          cards={generalCourses
            .slice(0, seeHowMany)
            .map((course) => getCardFromCourse(course, t, isFiltered))}
          button={
            generalCourses.length > seeHowMany ? (
              <CustomButtonText
                text="Load More"
                onClick={onLoadMore}
                style={{
                  letterSpacing: "0.1em",
                }}
              />
            ) : (
              <></>
            )
          }
          backgroundColor="#FFFFFF"
          disableCarousel={true}
        />
      ) : (
        <NoResult />
      )}
      {newsletter && (
        <BrickNewsletter
          title={newsletter.Newsletter.title}
          subtitle={newsletter.Newsletter.subtitle}
          buttonText={newsletter.Newsletter.buttonText}
          backgroundImageData={
            newsletter.backgroundImage[0].localFile.childImageSharp
              .gatsbyImageData
          }
          alternativeText={newsletter.Newsletter.background.alternativeText}
          style={{ padding: 0 }}
        />
      )}
    </Layout>
  );
};

const ListCourses = ({ data }) => {
  return <PrivateRoute component={Courses} data={data} />;
};
export default ListCourses;
